if (typeof window !== 'undefined') {
ReportManager = function (params, setInitialMEA) {
  this._init(params, setInitialMEA);
}

ReportManager.prototype.cleanup = function () {
  $(document).off('onSpirometerReadyToPrint')
  $(document).off('viewerApiReady')
  $(document).off('ViewerReadyToPrint')
  $(document).off('summaryParametersReady')
  $(document).off('eventsDataReady')
  $(document).off('onGotBCViewerRecordData')
  $(document).off('onGotBCViewerEventsData')
  $('.screenshot-iframe').remove()
  $('.subpage_wrapper.landscape').remove()
  $('body').removeClass('print_A4')
  $('body').removeClass('print_letter')
 // $('body').removeClass('report_elements_ready')
  $('body').removeClass('no_conclusion')
  $('body').removeClass('study_mode')
  $('html').removeClass('study_report')
  $('body').removeClass('locale_ru_RU')
  $('body').removeClass('locale_en_US')
  $('body').removeClass('locale_uk_UA')
  $('body').removeClass('locale_zh_TW')
  PatientDetailsTable.cleanup()
}

ReportManager.prototype._init = function(_, setInitialMEA) {
    // bcGlobals.userSettings.add_full_disclosure = false; // for tests
    $('body').addClass((bcGlobals.userSettings.paper_size == 'A4') ? 'print_A4' : 'print_letter');
    $('body').addClass(`locale_${bcGlobals.userSettings.locale}`)

    $('html').addClass('study_report');

    PatientDetailsTable.fillPrivateAttributes();

    $('body').toggleClass('no_conclusion', !(bcGlobals.has_conclusion == true));
    $('body').toggleClass('study_mode', (bcGlobals.is_study_mode == true));

    this._readyToPrintElementsCnt = 2; // Powered by logo, viewer elements (viewer ready to print)

    var poweredByLogo = $('#main_powered_by img');
    if (!poweredByLogo[0] || poweredByLogo[0].complete) {
      this._onElementReadyToPrint();
    } else {
      poweredByLogo.on('load', $.proxy(function() {
        this._onElementReadyToPrint();
      }, this));
    }

    if (bcGlobals.is_questionnaire) {
      if (bcGlobals.is_print_mode) {
         this._onElementReadyToPrint();
      }
      return
    }
    this._configByPaperSize = {
        A4: {
            viewerWidthCm: 18.5,
            viewerHeight: 700,
            viewerHeightCm: 14.5,
            rhythmHeight: 200,
            rhythmHeightCm: 2,
            fullDisclosureWidthMm: 255,
            fullDisclosureHeightMm: 175
        },
        Letter: {
            viewerWidthCm: 19.09,
            viewerHeight: 700,
            viewerHeightCm: 12,
            rhythmHeight: 200,
            rhythmHeightCm: 2,
            fullDisclosureWidthMm: 237,
            fullDisclosureHeightMm: 180
        }
    };

    this._config = this._configByPaperSize[bcGlobals.userSettings.paper_size];
    this._loadingElementsNumber = bcGlobals.userSettings.add_full_disclosure ? 6 : 5;
    this._controlViewerId = 'controlViewer';
    this._fullDisclosureViewerId = 'fullDisclosureViewer';
    this._viewerUrlAndParams = this._getViewerUrlAndParams();

    this._reportSubpage = null;

    this._fullDisclosureStep = 1;
    this._fullDisclosureStepsLimit = 600;
    this._patientName = bcGlobals.patientsManager.patients[bcGlobals.study.patient_hash_id].name || bcGlobals.generalLocale.anonymous;

    this._viewersList = {
        controlViewer: {
            api: null,
            width: this._config.viewerWidth,
            height: this._config.viewerHeight,
            shouldShow: false
        },
        firstChannelsViewer: {
            api: null,
            width: this._config.viewerWidth,
            height: this._config.viewerHeight,
            imgSelector: '#firstChannels',
            stateStrSelector: '#mainEcgState',
            shouldShow: true,
            showScale: true,
            stateParams: {}
        },
        lastChannelsViewer: {
            api: null,
            width: this._config.viewerWidth /2,
            height: this._config.viewerHeight,
            imgSelector: '#lastChannels',
            shouldShow: false,
            showScale: true,
            stateParams: {}
        },
        rhythmViewer: {
            api: null,
            width: this._config.viewerWidth,
            height: this._config.rhythmHeight,
            imgSelector: '#rhythm',
            stateStrSelector: '#rhythmState',
            stateParams: {
                zoomX: 25,
                zoomY: 10,
                requestedChannels: [1],
            },
            shouldShow: true,
            showScale: true,
            showAnnotations: false
        },
        fullDisclosureViewer: {
            api: null,
            showScale: true,
            width: this._config.viewerWidth,
            height: this._config.viewerHeight,
            stateParams: {
              timePosition: 0
            }
        }
    };

    this._eventsReadyTriggered = false;
    this._summaryParametersReadyTriggered = false;

    this.recordData = null;
    this.eventsData = null;

    if (bcGlobals.study.has_ecg_signal) {
    } else if ((bcGlobals.study.study_type & bcGlobals.studyTypes.spirometer) > 0) {
        if (window.spiroViewer && window.spiroViewer._readyToPrint) { // If siprometer is ready finish, else wait for onReadyToPrintEvent
            this._onAllElementsReady();
        }
    } else {
      this._onAllElementsReady();
    }

    $(document).on('onGotBCViewerRecordData', $.proxy(function(e) {
      this.recordData = e.recordData
    }, this));

    $(document).on('onGotBCViewerEventsData', $.proxy(function(e) {
      this.eventsData = e.eventsData
    }, this));

    $(document).on('onSpirometerReadyToPrint', $.proxy(function(e) {
        this._onAllElementsReady();
    }, this));

    $(document).on('viewerApiReady', $.proxy(function(e) {
      // Not the prettiest thing... but works for now
      // the reason its here and not in cleanup is
      // because update_report cleans the class (on init) but the viewer is not reloading
      // so its never getting set again...
      // if we remove the class here it will only be removed if we reload the viewer
      $('body').removeClass('report_elements_ready')
       return

       //this._viewersList[e.viewerId].api = e.api;
    }, this));

    this._reportBuildStep = 'waiting_for_control_viewer';

    $(document).on('ViewerReadyToPrint', $.proxy(function(e) {
      if (this._reportBuildStep === 'waiting_for_control_viewer') {
        this._onControlViewerReady();
      } else if (this._reportBuildStep === 'firstChannelsViewer' || this._reportBuildStep === 'lastChannelsViewer' ||
      this._reportBuildStep === 'rhythmViewer') {
        var url = window._bcViewer.viewerApi.getECGScreenshot();

        $(this._viewersList[this._reportBuildStep].imgSelector).attr('src', url).on('load', $.proxy(function() {
            this._onElementReady();
        }, this)).addClass('imageReady');

        this._addStateString(this._viewersList[this._reportBuildStep]);

        var nextStep = null;
        if (this._reportBuildStep === 'firstChannelsViewer') {
          nextStep = (this._viewersList['lastChannelsViewer'].shouldShow) ? 'lastChannelsViewer' : 'rhythmViewer';
        } else if (this._reportBuildStep === 'lastChannelsViewer') {
          nextStep = 'rhythmViewer';
        } else if (this._reportBuildStep === 'rhythmViewer') {
          nextStep = (bcGlobals.userSettings.add_full_disclosure) ? 'fullDisclosureViewer' : null;
        }

        (nextStep !== null) && this._nextReportStep(nextStep);
      } else if (this._reportBuildStep === 'fullDisclosureViewer') {
          this._addFullDisclosureImg();
      }
    }, this));



    $(document).on("summaryParametersReady", $.proxy(function(e) {
        if (!this._summaryParametersReadyTriggered) {
            this._summaryParametersReadyTriggered = true;
            if (typeof e.summaryParameters.medianTimeParameters !== 'undefined') {
                (bcGlobals.isVeterinarian === false) && StudySummary.fillSummaryParametersTable('.report_summary', e.summaryParameters);
                if (bcGlobals.isShowMeanElectricalAxisChart) {
                    StudySummary.drawMeanElectricalAxisChart('.report_summary', e.summaryParameters.medianAngleParameters.qrsAngle);
                    const initialMEA = MeasurementValuesManager.getMeaLabel(
                      e.summaryParameters.medianAngleParameters.qrsAngle
                    )
                    setInitialMEA(initialMEA ? { mea: initialMEA } : null)
                }
            } else {
                $('body').addClass('no_events'); // relevant only in report page
            }
            this._onEventsReady();
            this._onElementReady();
        }
    }, this));
    $(document).on("eventsDataReady", $.proxy(function(e) {
        if (!this._eventsReadyTriggered) {
            this._eventsReadyTriggered = true;
            (bcGlobals.isVeterinarian === false) && StudySummary.fillTopEvents('.report_summary', e.eventsData, 5);
            this._onEventsReady();
            this._onElementReady();
        }
    }, this));

    // $('.report_conclusion_text').on('keyup', $.proxy(this._onWritingReport, this));
    // $('.report_conclusion_text').on('focusout', $.proxy(this._onReportFocusout, this));
    //
    // $('#report_print').on('click', $.proxy(this._onPrint, this));
    //
    // $('#save_report').on('click', $.proxy(this._onSave, this));
    //
    //  $('#view_study').attr('href', $('#view_study').attr('href') + window.location.hash);

    var reportConclusion = $('.report_conclusion_text');
    if (reportConclusion[0]) {
        reportConclusion.on('keyup', RTLText.onTextChange);
        reportConclusion.on('keydown', RTLText.onTextChange);
        RTLText.setText(reportConclusion.get(0));
    }
};

ReportManager.prototype._nextReportStep = function(step) {
  this._reportBuildStep = step;
  if (this._reportBuildStep === 'firstChannelsViewer' || this._reportBuildStep === 'lastChannelsViewer' ||
      this._reportBuildStep === 'rhythmViewer' || this._reportBuildStep === 'fullDisclosureViewer') {
    var viewerSettings = this._viewersList[this._reportBuildStep]

    $('#bcViewer_wrapper').width(viewerSettings.width);
    $('#bcViewer_wrapper').height(viewerSettings.height);

    var _config = new window.bcViewer.Config ({});
    _config.eventsViewer.HIDDEN = true;
    _config.HRViewer.HIDDEN = true;

    _config.ecgViewer.HIDE_HEADER = true;
    _config.contextViewer.HIDDEN = true;
    _config.timelineViewer.HIDDEN = true;
    _config.controlPanel.HIDDEN = true;
    if (this._reportBuildStep === 'fullDisclosureViewer') {
      _config.ecgViewer.CACHE_WINDOW_SIZE = 1;
    }
    window.bcGlobals.reportMode = 'true'
    window.bcGlobals.showScale = viewerSettings.showScale.toString();
    window.bcGlobals.showAnnotations = (typeof this._viewersList[this._reportBuildStep].showAnnotations !== 'undefined') ? this._viewersList[this._reportBuildStep].showAnnotations.toString() : 'true'

    var params = {
      recordHash: window.bcGlobals.study.hash_id,
      recordURL:  window.bcGlobals.study.link,
      recordType: 2,
      studyType: 1, // 1 - rest, 2 - holter
      fullDisclosureViewer: (this._reportBuildStep === 'fullDisclosureViewer').toString(),
      remoteMonitor: false,
      sessionId: null,
      fullscreenMode: true,
      forcedState: this._viewersList[this._reportBuildStep].stateParams,
      cachedRecordData: this.recordData,
      cachedEventsData:  this.eventsData
    }
    window._bcViewer._init(params, _config );
  }
};

ReportManager.prototype._onControlViewerReady = function() {
    var i;
    var numberOfChannels = window._bcViewer.viewerApi.getNumberOfChannels();
    var requestedChannels = this._viewerUrlAndParams.params.requestedChannels;
    if (typeof requestedChannels === 'undefined') {
        requestedChannels = []
        for (var i=0; i<numberOfChannels; i++) requestedChannels.push(i);
    } else {
        requestedChannels = requestedChannels.split(',');
    }

    numberOfChannels = requestedChannels.length;

    var firstViewrChannels = [];
    var lastViewerChannels = [];

    // Less than 8 channels - use just one viewer
    // otherwise split the channels between the viewers
    var numChannelsInFirstViewer = (numberOfChannels < 8) ? numberOfChannels : Math.ceil(numberOfChannels/2);
    for (i=0; i<numberOfChannels; i++) {
        if (i<numChannelsInFirstViewer) {
            firstViewrChannels.push(parseInt(requestedChannels[i]));
        } else {
            lastViewerChannels.push(parseInt(requestedChannels[i]));
        }
    }

    if (lastViewerChannels.length > 0) {
        this._viewersList.lastChannelsViewer.shouldShow = true;
        this._viewersList.firstChannelsViewer.showScale = false;
    } else {
        this._loadingElementsNumber--;
    }

    this._viewersList.firstChannelsViewer.stateParams.requestedChannels = firstViewrChannels;
    this._viewersList.lastChannelsViewer.stateParams.requestedChannels = lastViewerChannels;

    this._adjustViewersDimensions(window._bcViewer.viewerApi, numberOfChannels);

    !bcGlobals.is_study_mode && $('#extra_signature').show();
    this._nextReportStep('firstChannelsViewer');
};

ReportManager.prototype._adjustViewersDimensions = function(api, numberOfChannels) {
    // TBD: get viewer height from mm and get the correct width width the border.
    // the viewer should load without border and without context viewer \ timeline viewer...

    var viewerWidth = api.mmToPixels(this._config.viewerWidthCm*10)+2; // 2 is the viewer border...

    var viewerHeight = api.mmToPixels(this._config.viewerHeightCm*10)+2; // 2 is the viewer border
    var rhythmViewerHeight = api.mmToPixels(this._config.rhythmHeightCm*10)+2; // 2 is the viewer border

    this._config.viewerWidth = viewerWidth;
    this._viewersList.firstChannelsViewer.width = viewerWidth;
    this._viewersList.firstChannelsViewer.height = viewerHeight;
    this._viewersList.lastChannelsViewer.width = viewerWidth/2;
    this._viewersList.lastChannelsViewer.height = viewerHeight;
    this._viewersList.rhythmViewer.width = viewerWidth;
    this._viewersList.rhythmViewer.height = rhythmViewerHeight;

    var fdViewerWidth = api.mmToPixels(this._config.fullDisclosureWidthMm) + 2;
    var fdViewerHeight = api.mmToPixels(this._config.fullDisclosureHeightMm) + 2;
    this._viewersList.fullDisclosureViewer.width = fdViewerWidth;
    this._viewersList.fullDisclosureViewer.height = fdViewerHeight;

    $(this._viewersList.firstChannelsViewer.imgSelector).width(this._config.viewerWidthCm + 'cm');
    $(this._viewersList.firstChannelsViewer.imgSelector).height(this._config.viewerHeightCm + 'cm');

    $(this._viewersList.rhythmViewer.imgSelector).width(this._config.viewerWidthCm + 'cm');
    $(this._viewersList.rhythmViewer.imgSelector).height(this._config.rhythmHeightCm + 'cm');

    if (numberOfChannels > 7) {
        this._viewersList.firstChannelsViewer.width = this._config.viewerWidth/2;
        $(this._viewersList.firstChannelsViewer.imgSelector).width((this._config.viewerWidthCm/2) + 'cm');
        $(this._viewersList.lastChannelsViewer.imgSelector).width((this._config.viewerWidthCm/2) + 'cm');
        $(this._viewersList.lastChannelsViewer.imgSelector).height(this._config.viewerHeightCm + 'cm');
    }
};

ReportManager.prototype._getViewerUrlAndParams = function() {
    //var viewerUrl = window.location.href.replace(window.location.pathname,'/viewer');

	var viewerUrl = window.location.href.replace(
      window.location.pathname,
      '/viewer'
    )
    // viewerUrl = viewerUrl.replace('hash_id','record_hash');
    var viewerUrlParts = viewerUrl.split('#');

   // fix record_type
    var url =
		viewerUrlParts[0] +
		(viewerUrlParts[0].indexOf('?') > -1 ? '&' : '?') +
		'record_hash=' +
		bcGlobals.study.hash_id +
		'&reportMode=true' +
		'&record_type=2';

    var paramsStr = viewerUrlParts[1];

    var paramsObj = {};
    if (typeof paramsStr !== 'undefined') {
        var params = paramsStr.split('&');
        for (var i=0;i<params.length;i++) {
            var paramsParts = params[i].split('=');
            paramsObj[paramsParts[0]] = paramsParts[1];
        }
    }

    return {
        url: url,
        params: paramsObj
    };
};

ReportManager.prototype._addStateString = function(viewer) {
    if (typeof viewer.stateStrSelector !== 'undefined') {
        var state = window._bcViewer.viewerApi.getState();
        var stateStrItem = $(viewer.stateStrSelector);
        stateStrItem.find('.stateVisibleStartTime').text(state.visibleStartTime);
        stateStrItem.find('.stateZoom').text(state.zoom);
        stateStrItem.find('.stateAmpl').text(state.ampl);
        stateStrItem.find('.currentViewString').text(state.currentViewString);
        stateStrItem.find('.patientNameString').text(this._patientName);
    }
};

ReportManager.prototype._onElementReady = function() {
  this._loadingElementsNumber--;
    if (this._loadingElementsNumber === 0) {
        this._onAllElementsReady();
    }
};

ReportManager.prototype._onAllElementsReady = function() {
    $('body').addClass('report_elements_ready');
    $('#loader_wrapper').hide();
    $('.loading_element').show();
    var reportConclusion = $('.report_conclusion_text');
    if (reportConclusion[0]) {
        autosize(reportConclusion);
    }
    if (bcGlobals.is_print_mode) {
        window.focus();

        this._onElementReadyToPrint();
        // close the window after print or cancel - remove because it cause issues on no-ecg studies
        // https://stackoverflow.com/a/12070246/4437379
        // https://stackoverflow.com/a/32920693/4437379
        // https://www.tjvantoll.com/2012/06/15/detecting-print-requests-with-javascript/
       // setTimeout(window.close, 0);
       // window.onmousemove = function() { window.close(); } // fallback when window.close isn't working
    }
};

ReportManager.prototype._onEventsReady = function() {
    if(!this._eventsReadyTriggered || !this._summaryParametersReadyTriggered) return;
    if ($('body').hasClass('no_events') || !bcGlobals.is_study_mode) return;
    !bcGlobals.is_study_mode && $('#extra_signature').show();
};

ReportManager.prototype._addSubpage = function(additionalClass) {
    var subpageWrapper = $('<div></div>');
    subpageWrapper.addClass('subpage_wrapper');
    additionalClass && subpageWrapper.addClass(additionalClass);
    var subpageContainer = $('<div></div>');
    subpageContainer.addClass('subpage_container');

    subpageWrapper.append(subpageContainer);
    $('body').append(subpageWrapper);

    return subpageContainer;
};

ReportManager.prototype._addFullDisclosureNext = function() {
    this._fullDisclosureStep++;

    var isLastWindow = true;
    if (this._fullDisclosureStep <= this._fullDisclosureStepsLimit) {
        isLastWindow = window._bcViewer.viewerApi.jumpToNextWindow();
    }

    if (isLastWindow) {
        this._onElementReady();
    }
};

ReportManager.prototype._addFullDisclosureImg = function() {
    var viewer = this._viewersList[this._fullDisclosureViewerId];
    var url = window._bcViewer.viewerApi.getECGScreenshot();
    var w = this._config.fullDisclosureWidthMm + 'mm';
    var h = this._config.fullDisclosureHeightMm + 'mm';
    this._reportSubpage = this._addSubpage('landscape');
    var imgId = 'fd' + this._fullDisclosureStep;
    var imgWrapper = $('<div></div>').addClass('imgWrapper');
    viewer.stateStrSelector = '#state_'+imgId;

    var viewerImg = $('<img></img>').attr('id', imgId).width(w).height(h);
    var viewerState = $('<div></div>').attr('id', 'state_'+imgId).addClass('stateWrapper')
                        .append('<span class="patientNameString"></span>')
                        .append('<span class="currentViewString"></span>');
    viewer.imgSelector = '#' + imgId;
    imgWrapper.append(viewerState).append(viewerImg);
    imgWrapper.appendTo(this._reportSubpage);

    $(viewer.imgSelector).attr('src', url).on('load', $.proxy(function() {
        this._addStateString(viewer);
        this._addFullDisclosureNext();
    }, this));
};

ReportManager.prototype._onWritingReport = function() {
    // if (bcGlobals.isAuthorizedUser) {
    //     $('#save_report').removeAttr('disabled');
    // }
};

ReportManager.prototype._onReportFocusout = function(conclusion, callback) {
//    var conclusion = $('.report_conclusion_text').val();

//    this._updateReport(conclusion);
};

// ReportManager.prototype._onSave = function(conclusion, callback) {
//     var conclusion = $('.report_conclusion_text').val();
//     var measurementValues = MeasurementValuesManager.getValues();
//     this._updateReport(conclusion, measurementValues.values, measurementValues.meaLabel);
//     $('#save_report').attr('disabled', 'disabled');
// };
//
// ReportManager.prototype._updateReport = function(conclusion, measurementValues, meaLabel, callback) {
//   $('body').toggleClass('no_conclusion', !conclusion);
//   var data = {
//     hash_id: bcGlobals.study.hash_id,
//     conclusion: conclusion,
//     state: window.location.hash.substring(1),
//     measurement_values: JSON.stringify(measurementValues),
//     mea_label: meaLabel
//   };
//   if (bcGlobals.reports.length > 0) {
//     data.report_id = bcGlobals.reports[0].id;
//   }
//
//   $.ajax({
//     type: 'POST',
//     url: '/api/update_report',
//     data: data
//   }).done(function (result) {
//     $('#report_signature_creator').text(result.report_signature_creator);
//     $('#report_signature_date').text(result.report_signature_date);
//     $('.digital_signature_img').attr('src', result.report_digital_signature_img);
//     $('.digital_signature_img').toggleClass('no_digital_signature', !result.report_digital_signature_img);
//     bcGlobals.reports = [result.report];
//   });
// };
//
ReportManager.prototype._onPrint = function() {
    window.print();
};

ReportManager.prototype._onElementReadyToPrint = function() {
  this._readyToPrintElementsCnt--;
  (this._readyToPrintElementsCnt === 0) && this._onPrint();
};

window.reportManager = ReportManager;

}
